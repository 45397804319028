const MenuConfig = {
    header: {
        menuItem: [
            {
                id: 1,
                title: "Payments",
                page: "/#payments",
            },
            {
                id: 2,
                title: "Tokenization",
                page: "/#tokenization",
            },
            {
                id: 3,
                title: "Custody",
                page: "/#custody",
            },
            {
                id: 4,
                title: "Trading & Network",
                page: "/#trading",
            },
            {
                id: 5,
                title: "Tech",
                page: "technology",
            },
        ],
        companyPages: [
            {
                id: 1,
                title: "Solutions",
                url: "solutions",
            },
            {
                id: 2,
                title: "Technology",
                url: "technology",
            },
            {
                id: 3,
                title: "Careers",
                url: "careers",
            },
            {
                id: 4,
                title: "About",
                url: "about",
            },
        ],
        resourcePages: [
            {
                id: 3,
                title: "Get An Account",
                url: "https://wallet.everest.org/",
            },
            {
                id: 4,
                title: "Schedule a Demo",
                url: "mailto:contact@everest.org?subject=Demo Request",
            },
        ],
        legalPages: [
            {
                id: 1,
                title: "Privacy Policy",
                url: "privacy-policy",
            },
            {
                id: 2,
                title: "Terms of Use",
                url: "terms-of-use",
            },
            {
                id: 3,
                title: "Compliance",
                url: "compliance",
            },
            {
                id: 4,
                title: "Complaints",
                url: "complaints",
            },
        ],
        socialPages: [
            {
                id: 1,
                title: "facebook",
                url: "https://facebook.com/everestdotorg/",
                icon: {
                    header: "/images/facebook.svg",
                    footer: "/images/facebook.webp",
                },
            },
            {
                id: 2,
                title: "twitter",
                url: "https://twitter.com/EverestDotOrg",
                icon: {
                    header: "/images/twitter.svg",
                    footer: "/images/twitter.webp",
                },
            },
            {
                id: 3,
                title: "reddit",
                url: "https://www.reddit.com/r/EverestDotOrg/",
                icon: {
                    header: "/images/reddit.svg",
                    footer: "/images/reddit.webp",
                },
            },
            {
                id: 4,
                title: "medium",
                url: "https://everestdotorg.medium.com/",
                icon: {
                    header: "/images/medium.svg",
                    footer: "/images/medium.webp",
                },
            },
        ],
    },
};

export default MenuConfig;
