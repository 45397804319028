import React from "react";

import Layout from "../../components/Layout/Layout";
import Button from "../../components/Button";
import ContentPage from "../../components/ContentPage/ContentPage";

import data from "./content.json";
import styles from "./complaints.module.scss";

const Complaints = () => {
    const { content } = data;
    return (
        <Layout>
            <div className={styles.complaints}>
                <h1>Complaints</h1>
                <p>
                    Customer Rights
                    <Button
                        variant="primary"
                        text="Read Here"
                        page="https://ec.europa.eu/info/sites/info/files/business_economy_euro/banking_and_finance/documents/leaflet-your-rights-payments-eu_en.pdf"
                        external
                    />
                </p>
            </div>
            <ContentPage content={content} />
        </Layout>
    );
};

export default Complaints;
