import Layout from "../../components/Layout/Layout";
import EnterpriseHero from "./sections/EnterpriseHero";
import EnterpriseFeature from "./sections/EnterpriseFeature";
import EnterpriseContent from "./sections/EnterpriseContent";

import data from "./content.json";
import "./sections/style.css";
import '../../global.css'

const EnterpriseAPIs = () => {
    const { features, content, hero } = data;

    return (
        <Layout>
            <main className="apisPage">
                { hero && <EnterpriseHero hero={hero} /> }
                { features && <EnterpriseFeature features={features} /> }
                { content && <EnterpriseContent content={content} /> }
            </main>
        </Layout>
    );
};

export default EnterpriseAPIs;
