import React, { useState } from "react";
import Modal from "react-modal";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./Popup.module.scss";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const Popup = ({ buttonText }) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className={styles.popup}>
            <button onClick={openModal}>{buttonText}</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <img
                    className={styles.popup_ico}
                    src={`${toAbsoluteUrl("/favicon.webp")}`}
                    alt="ico"
                />
                <p>
                    You're about to leave the <b>everest.org</b> website.
                </p>
                <div className={styles.popup_btns}>
                    <div className={styles.popup_btns_cancel}>
                        <a href onClick={closeModal}>
                            Cancel
                        </a>
                    </div>
                    <div className={styles.popup_btns_proceed}>
                        <a href="https://www.mfsa.mt/financial-services-register/result/?id=16256">
                            Proceed
                        </a>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Popup;
