import { toAbsoluteUrl } from "../../../_helpers/utils";
import ReactMarkdown from "react-markdown";

const EnterpriseFeature = ({ features }) => {

    return (
        <section className="mainFeatures" id="features">
            <div className="mainContainer">
                <h2 className="mainH2 wow fadeInUp" data-wow-delay=".3s" dangerouslySetInnerHTML={{ __html : features.title }}></h2>  

                <div className="allFeatures">
                    { features.list && features.list.map((item, i) => (
                        <div key={i} className="featureSingle">
                            <div className="featureSingleIn wow fadeInUp" data-wow-delay=".3s">
                                <div className="featureSingleHead">
                                <img
                                    id="icon"
                                    alt={item.title}
                                    src={toAbsoluteUrl(item.image)}
                                />
                                <h3>{ item.title }</h3>
                                <p className="mainP nwow fadeInUp" data-wow-delay=".3s"><ReactMarkdown children={item.content} /></p>
                                </div>
                                { /* item.url &&
                                <div className="featureBtn">
                                    <a href={item.url} className="ctaGreen nwow fadeInUp" data-wow-delay=".3s">View More</a>
                                </div>
                                */ }
                            </div>
                        </div>
                    )) }
                </div>
            </div>
        </section>
    )
}

export default EnterpriseFeature;