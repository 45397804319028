import React from "react";
import ReactMarkdown from "react-markdown";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./Subscription.module.scss";

const Subscription = ({ title, description }) => {
    return (
        <div className={styles.subscription}>
            <div className={styles.subscription__inner}>
                <h2 className="wow fadeInUp" data-wow-delay=".3s">
                    {title}
                </h2>
                { description && 
                    <article className="wow fadeInUp" data-wow-delay=".4s">
                        {description && <ReactMarkdown children={description} />}
                    </article>
                }
                <div className={styles.subscription__inner_socials}>
                    <div className={styles.subscription__inner_socials_wrapper}>
                        <img
                            alt="twitter"
                            src={toAbsoluteUrl("/images/twitter.svg")}
                        />
                        <a
                            href="https://twitter.com/EverestDotOrg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            @EverestDotOrg
                        </a>
                    </div>

                    <div className={styles.subscription__inner_socials_wrapper}>
                        <img
                            alt="youtube"
                            src={toAbsoluteUrl("/images/discord.svg")}
                        />
                        <a
                            href="https://discord.gg/YQMP4aTYAY"
                            target="_blank"
                            rel="noreferrer"
                        >
                            EverestDotOrg
                        </a>
                    </div>

                    <div className={styles.subscription__inner_socials_wrapper}>
                        <img
                            alt="medium"
                            src={toAbsoluteUrl("/images/medium.svg")}
                        />
                        <a
                            href="https://everestdotorg.medium.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            EverestDotOrg
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscription;
