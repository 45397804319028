const PaymentsInstall = ({ install }) => {

    return (
        <section className="mainInstall">
            <div className="mainContainer">
                <div className="mainContent">
                    <h2 className="mainH2 wow fadeInUp" data-wow-delay=".3s" dangerouslySetInnerHTML={{ __html : install.title }}></h2>                 
                    <div className="box wow fadeInUp" data-wow-delay=".3s">
                        <p className="mainP">{install.description}</p>
                        <a className="ctaBlue" href={install.button_link_1}>{install.button_label_1}</a>
                    </div>
                    <div className={`steps count${install.list.length}  wow fadeInUp`} data-wow-delay=".3s">
                        <div className="bar"></div>
                        <div className="stepsWrap">
                            { install.list && install.list.map((item, i) => (
                            <div key={i} className="step">
                                <div className="stepIn">
                                    <span className="number">{i + 1}</span>
                                    <p className="mainP" dangerouslySetInnerHTML={{ __html : item.title }}></p>
                                </div>
                            </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentsInstall;