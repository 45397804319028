import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import $ from "jquery";

import styles from "./solution-nav.module.scss";

const SolutionNav = ({ detail }) => {
    const sectionElement = useRef(null);
    const stickySectionElement = useRef(null);
    const [isSticky, setIsSticky] = useState(false);
    const [isStickyShow, setIsStickyShow] = useState(false);

    useEffect(() => {
        window.lastScrollTop = 0;

        const handleScroll = () => {
            const headerHeight = $(".MuiAppBar-root").outerHeight();
            const offsetOfElemNextMain = $("main").next().offset();

            // if nav section is passed on browser
            if (
                window.scrollY + window.innerHeight >
                offsetOfElemNextMain.top
            ) {
                const top = parseInt(
                    $(stickySectionElement.current).css("top")
                );
                const newTop =
                    offsetOfElemNextMain.top -
                    window.innerHeight +
                    headerHeight;

                if (top !== newTop) {
                    $(stickySectionElement.current).css({
                        position: "absolute",
                        top: newTop + "px",
                    });
                }
            } else {
                $(stickySectionElement.current).removeAttr("style");

                // while scrolling down, if browser scroll is over nav, set the sticky
                if (
                    window.scrollY >
                    sectionElement.current.offsetTop - headerHeight
                ) {
                    if (!isSticky) setIsSticky(true);
                } else {
                    if (isSticky) setIsSticky(false);
                }
            }

            // detect scroll up & down
            if (window.scrollY < window.lastScrollTop) {
                setIsStickyShow(true);
            } else {
                setIsStickyShow(false);
            }

            window.lastScrollTop = window.scrollY;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSticky]);

    return (
        <>
            <section
                id="anchor"
                ref={sectionElement}
                className={styles.section}
            >
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Link to="/">
                                <div className={styles.nav}>
                                    <span>Home</span>
                                </div>
                            </Link>
                        </div>
                        {detail &&
                            detail.map((info, i) => (
                                <div key={i} className={styles.col}>
                                    <Link
                                        className={styles.nav}
                                        activeClass={styles.nav_active}
                                        to={info.section_id}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: info.nav_title,
                                            }}
                                        ></span>
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </section>
            <section
                ref={stickySectionElement}
                className={`${styles.sticky_section} ${
                    isSticky ? styles.section__is_sticky : ""
                } ${isStickyShow ? styles.section__is_sticky_show : ""}`}
            >
                <div className={styles.container}>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            <Link to="/">
                                <div className={styles.nav}>
                                    <span>Home</span>
                                </div>
                            </Link>
                        </div>
                        {detail &&
                            detail.map((info, i) => (
                                <div key={i} className={styles.col}>
                                    <Link
                                        className={styles.nav}
                                        activeClass={styles.nav_active}
                                        to={info.section_id}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: info.nav_title,
                                            }}
                                        ></span>
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default SolutionNav;
