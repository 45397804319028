import React from "react";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./TeamCards.module.scss";

const TeamCards = ({ cards }) => {
    return (
        <div className={styles.cards + " wow fadeInUp"} data-wow-delay=".3s">
            {cards.length &&
                cards.map((card, idx) => (
                    <div key={idx} className={styles.cards__wrapper}>
                        {card.avatar && (
                            <img
                                alt={card.name}
                                src={toAbsoluteUrl(`${card.avatar}`)}
                            />
                        )}
                        <div
                            key={idx}
                            className={styles.cards__wrapper_content}
                        >
                            <h3>{card.name}</h3>
                            <p>{card.position}</p>
                            {card.linkedin && (
                                <a
                                    href={card.linkedin}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt={card.linkedin}
                                        src={toAbsoluteUrl(
                                            "/images/linkedin.svg"
                                        )}
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default TeamCards;
