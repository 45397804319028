import React from "react";
import ReactMarkdown from "react-markdown";

import styles from "./Technologies.module.scss";
import Button from "../../components/Button";

const Technologies = ({ technologies }) => {
    return (
        <div className={styles.technologies}>
            {technologies &&
                technologies.map((technology, idx) => (
                    <div
                        key={idx}
                        id={idx}
                        className={styles.technologies__wrapper}
                    >
                        <lottie-player
                            class={
                                styles.technologies__wrapper_lottie +
                                " wow rotateIn"
                            }
                            data-wow-delay=".3s"
                            src={technology.animation}
                            background="transparent"
                            speed=".5"
                            loop
                            autoplay
                        ></lottie-player>
                        <h2 className="wow fadeInUp" data-wow-delay=".3s">
                            {technology.title}
                        </h2>
                        <article className="wow fadeInUp" data-wow-delay=".4s">
                            {technology.description && (
                                <ReactMarkdown
                                    children={technology.description}
                                />
                            )}
                            <p style={{marginTop: "2em"}}>
                                {technology.button_link && (
                                    <Button
                                        variant="primary"
                                        text={technology.button_label}
                                        page={technology.button_link}
                                        external
                                    />
                                )}
                            </p>
                        </article>
                    </div>
                ))}
        </div>
    );
};

export default Technologies;
