import Layout from "../../components/Layout/Layout";
import PaymentsHero from "./sections/PaymentsHero";
import PaymentsIntegrate from "./sections/PaymentsIntegrate";
import PaymentsTransaction from "./sections/PaymentsTransaction";
import PaymentsNewsletter from "./sections/PaymentsNewsletter";
import PaymentsFlexible from "./sections/PaymentsFlexible";
import PaymentsFeedback from "./sections/PaymentsFeedback";
import PaymentsInstall from "./sections/PaymentsInstall";

import data from "./content.json";
import "./sections/style.css";
import '../../global.css'

const PaymentsPage = () => {
    const { hero, integrate, transaction, flexible, feedback, install } = data;

    return (
        <Layout>
            <main className="paymentsPage">
                { hero && <PaymentsHero hero={hero} /> }
                { integrate && <PaymentsIntegrate integrate={integrate} /> }
                { transaction && <PaymentsTransaction transaction={transaction} /> }
                <PaymentsNewsletter />
                { flexible && <PaymentsFlexible flexible={flexible} /> }
                { feedback && <PaymentsFeedback feedback={feedback} /> }
                { install && <PaymentsInstall install={install} /> }
            </main>
        </Layout>
    );
};

export default PaymentsPage;
