import React from "react";

import Layout from "../../components/Layout";
import ContentPage from "../../components/ContentPage";

import data from "./content.json";

const Cookie = () => {
    const { content } = data;
    return (
        <Layout>
            <ContentPage content={content} />
        </Layout>
    );
};

export default Cookie;
