import React from "react";
import ReactMarkdown from "react-markdown";

import styles from "./ContentPage.module.scss";

const ContentPage = ({ content }) => {
    return (
        <article className={styles.content}>
            {content && <ReactMarkdown children={content} />}
        </article>
    );
};

export default ContentPage;
