import { Link } from "react-scroll";
import styles from "./solution-hero.module.scss";
import SolutionHeroIcon from "./SolutionHeroIcon";
import Button from "../../../components/Button";

const SolutionHero = ({ hero, detail }) => {
    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.row}>
                    <div className={styles.right_col}>
                        <div className={styles.info_row}>
                            {detail &&
                                detail.map((info, i) => (
                                    <div key={i} className={styles.info_col}>
                                        <Link
                                            to={info.section_id}
                                            spy={false}
                                            smooth={true}
                                            offset={-70}
                                            duration={500}
                                        >
                                            <div className={styles.info_image}>
                                                <SolutionHeroIcon info={info} />
                                            </div>
                                            <div
                                                className={styles.info_title}
                                                dangerouslySetInnerHTML={{
                                                    __html: info.hero_title,
                                                }}
                                            ></div>
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className={styles.left_col}>
                        <div className={styles.title}>
                            <h1 className="wow fadeInUp" data-wow-delay=".3s">
                                {hero.title}
                            </h1>
                        </div>
                        <div
                            className={styles.content + " wow fadeInUp"}
                            data-wow-delay=".4s"
                        >
                            <p>{hero.description}</p>
                        </div>
                        {hero.button_link && (
                            <Button
                                variant="primary"
                                text={hero.button_label}
                                page={hero.button_link}
                                external
                            />
                        )}
                    </div>
                </div>
                {/* <div className={styles.download_row}>
                    <div className={styles.download}>
                        <div className={styles.download_inner_row}>
                            <div className={styles.download_label}>
                                DOWNLOAD THE APP
                            </div>
                            <div className={styles.download_link_row}>
                                <div className={styles.download_playstore}>
                                    <img
                                        alt="playstore"
                                        src={toAbsoluteUrl("/playstore.webp")}
                                    />
                                </div>
                                <div className={styles.download_appstore}>
                                    <svg
                                        width="35"
                                        height="41"
                                        viewBox="0 0 35 41"
                                        fill="none"
                                    >
                                        <path
                                            d="M29.1482 39.3489C26.8972 41.5072 24.4136 41.1707 22.0464 40.1532C19.5296 39.1151 17.2287 39.0494 14.5707 40.1532C11.2607 41.5646 9.50388 41.1543 7.51037 39.3489C-3.74464 27.9012 -2.08338 10.463 10.7083 9.80646C13.8107 9.97059 15.9828 11.5011 17.8102 11.6282C20.5263 11.0825 23.1262 9.51924 26.0334 9.7244C29.5262 10.0034 32.1385 11.3656 33.8828 13.8152C26.6979 18.0825 28.4007 27.4376 35 30.0636C33.6793 33.4897 31.9848 36.8748 29.1441 39.3777L29.1482 39.3489ZM17.561 9.68337C17.2246 4.5955 21.3985 0.410312 26.1995 0C26.8598 5.86747 20.8004 10.2578 17.561 9.68337Z"
                                            fill="#1A1A1A"
                                        />
                                    </svg>
                                </div>
                                <div className={styles.download_deskstore}>
                                    <svg
                                        width="41"
                                        height="34"
                                        viewBox="0 0 41 34"
                                        fill="none"
                                    >
                                        <path
                                            d="M39.5357 0H1.46429C0.654353 0 0 0.653495 0 1.46237V24.129C0 24.9379 0.654353 25.5914 1.46429 25.5914H18.8527V30.7097H10.9821C10.5795 30.7097 10.25 31.0387 10.25 31.4409V33.6344C10.25 33.8355 10.4147 34 10.6161 34H30.3839C30.5853 34 30.75 33.8355 30.75 33.6344V31.4409C30.75 31.0387 30.4205 30.7097 30.0179 30.7097H22.1473V25.5914H39.5357C40.3456 25.5914 41 24.9379 41 24.129V1.46237C41 0.653495 40.3456 0 39.5357 0ZM37.7054 22.3011H3.29464V3.29032H37.7054V22.3011Z"
                                            fill="#1A1A1A"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={styles.scroll_bottom}>
                    <Link
                        to="anchor"
                        spy={true}
                        smooth={true}
                        duration={500}
                        style={{ cursor: "pointer" }}
                    >
                        <svg
                            width="24"
                            height="14"
                            viewBox="0 0 24 14"
                            fill="none"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M-5.24544e-07 1.33357C-5.09624e-07 1.6749 0.130662 2.01623 0.390659 2.27623L11.0572 12.9427C11.5785 13.4641 12.4212 13.4641 12.9425 12.9427L23.609 2.27623C24.1303 1.7549 24.1303 0.912247 23.609 0.390921C23.0877 -0.130405 22.245 -0.130405 21.7237 0.390921L11.9998 10.1148L2.27597 0.390922C1.75464 -0.130404 0.911985 -0.130404 0.390659 0.390922C0.130662 0.650918 -5.39464e-07 0.992245 -5.24544e-07 1.33357Z"
                                fill="url(#paint0_linear)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="11.7073"
                                    y1="14.8668"
                                    x2="11.7117"
                                    y2="-0.000331699"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#35E0F4" />
                                    <stop offset="0.0696" stopColor="#33D7F0" />
                                    <stop offset="0.1854" stopColor="#2EBDE4" />
                                    <stop offset="0.333" stopColor="#2594D0" />
                                    <stop offset="0.505" stopColor="#195BB6" />
                                    <stop offset="0.6164" stopColor="#1132A3" />
                                    <stop offset="1" stopColor="#1132A3" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </Link>{" "}
                </div>
            </div>
        </section>
    );
};

export default SolutionHero;
