export const feed = [
    {
        title: 'Everest’s Real World Assets (RWA) Tokenization Platform',
        pubDate: '2024-01-09 00:00:00',
        link: 'https://everestdotorg.medium.com/everests-real-world-assets-rwa-tokenization-platform-b57a76d5bcff',
        thumbnail: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*8028_WElewLItveaYoWE6w.png',
        description: 'Turnkey Solution for Traditional Finance and Token Issuers — Bridging TradFi and Crypto: Everest’s Compliant, Turnkey Solution In the rapidly evolving landscape of finance, Everest’s Real World Assets (RWA) Tokenization Platform stands as a pivotal bridge connecting traditional finance, TradFi (banks, brokerages, payment companies) and the crypto space. This turnkey, licensed, and regulated solution holds the key to unlocking the value of diverse assets globally. By combining…',    
    },
    {
        title: 'Everest Newsletter — November 2023',
        pubDate: '2023-11-21 00:00:00',
        link: 'https://everestdotorg.medium.com/everest-newsletter-november-2023-29474e0d2bbb',
        thumbnail: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*hsb9JGSWoGtOFI5y0rCDVQ.png',
        description: 'A Note From Our CEO & Co-Founder, Bob Reid I’ll jump right into my sense on the state of the market, and Everest’s place in it…..it feels like crypto winter is truly thawing, and Everest is launching products in time for the coming bull run. Regarding the market, I’ve been looking for one of two indicators to point to…',    
    },
    {
        title: 'Everest Exchange',
        pubDate: '2023-10-16 00:00:00',
        link: 'https://everestdotorg.medium.com/everest-exchange-a0f8170284ff',
        thumbnail: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*QkZd6lo47HIXZGitAKrFhw.png',
        description: 'Buy, Swap, Sell, Save & Earn! — Lowest Fees: No gas fees — like a CEX Over 100+ Tokens w/ Deep Liquidity: BTC, ETH, XRP, SOL, LINK & many more No KYC*: Like DeFi, but from a regulated and licensed custodian Swap ANY token: No need to bridge. Hold multiple network tokens in the same wallet, like…',
    },
    {
        title: 'AMA With Bob Reid (Thu 12th Oct 2023) — Transcript',
        pubDate: '2023-10-12 00:00:00',
        link: 'https://everestdotorg.medium.com/ama-with-bob-reid-thu-12th-oct-2023-transcript-5ef1fca70e43',
        thumbnail: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*qqLtygNw5uHfWbYKKEqVug.png',
        description: 'Jon: Will the web wallet be accessible via mobile in beta? I understand there are apps, but one would hope that it would still be accessible through a responsive site. The plan is to have the wallet be accessible via mobile in beta Yoki: If the tokenized stocks come by…',
    },
    {
        title: 'Verified Wallet on Twitter: Get Paid to Prove You’re Not a Bot, Privately!',
        pubDate: '2023-10-07 00:00:00',
        link: 'https://everestdotorg.medium.com/verified-wallet-on-twitter-get-paid-to-prove-your-not-a-bot-privately-a3cb3688ee4c',
        thumbnail: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*w-QX8AkfvvxDvMmv5Aq6kg.png',
        description: 'We are excited to announce our first Verified EverWallet campaign, giving Twitter users the opportunity to claim their free verified wallet, and start earning affiliate rewards! We’ll soon be adding multiple social networks such as Instagram and LinkedIn, but for now, we’re kicking things off with Twitter… What Is a Verified EverWallet? A Verified EverWallet…',
    },
    {
        title: 'MiCA 2.0: Regulatory Clarity for DeFi',
        pubDate: '2023-05-01 00:00:00',
        link: 'https://everestdotorg.medium.com/mica-2-0-regulatory-clarity-for-defi-8477e80b2a52',
        thumbnail: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*Cmy_-KtuaXXJgdO9dHgAsg.png',
        description: 'You wanted “regulatory clarity”? Well here’s a great start. Identity verification and compliance, especially for DeFi, are loud and clear in the latest study by the European Parliament: https://www.europarl.europa.eu/RegData/etudes/STUD/2023/740083/IPOL_STU(2023)740083_EN.pdf Whenever new policies such as these are released, most lawmakers and regulators know what needs to get done, and they do the best they can in every v1.0, leaving a…',
    }
]