import React from "react";

import Layout from "../../components/Layout/Layout";
import ContentPage from "../../components/ContentPage/ContentPage";

import data from "./content.json";

const Terms = () => {
    const { content } = data;
    return (
        <Layout>
            <ContentPage content={content} />
        </Layout>
    );
};

export default Terms;
