import React from "react";
import Slider from "react-slick";
import ReactMarkdown from "react-markdown";

import { toAbsoluteUrl } from "../../_helpers/utils";

import data from "./tweets.json";

import "./TwitterPosts.scss";

const TwitterPosts = () => {
    const { tweets } = data[0];

    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    autoplay: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="tweets">
            <Slider {...settings}>
                {tweets &&
                    tweets.map((tweet, idx) => (
                        <div key={idx} className="tweets__wrapper">
                            <div className="tweets__wrapper_top">
                                <img
                                    alt="twitter"
                                    src={toAbsoluteUrl("/everest-twitter-new.jpg")}
                                />
                                <div>
                                    <h6>{tweet.title}</h6>
                                    <p>
                                        <a
                                            href="https://twitter.com/EverestDotOrg"
                                            target="_blank"
                                            without
                                            rel="noreferrer"
                                        >
                                            {tweet.handle}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="tweets__wrapper_bottom">
                                <ReactMarkdown children={tweet.tweet} />
                            </div>
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default TwitterPosts;
