import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from "./reportWebVitals";

import {
    SHOP_HOST,
    BASE_URL,
	REMIT_PATH,
	WALLET_URL,
} from './js/variables';

// Redirect to everest.org if we are coming from shop.everest.org URL
if (window.location.host === SHOP_HOST) {
    window.location.replace(`${BASE_URL}`);
}

// Redirect to wallet URL if we are coming at /remit path
if (window.location.pathname === REMIT_PATH) {
    window.location.replace(`${WALLET_URL}`);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
