import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import styles from "./solution-hero.module.scss";

const SolutionHeroIcon = ({ info }) => {
    const divEl = useRef(null);

    useEffect(() => {
        Lottie.loadAnimation({
            container: divEl.current,
            path: info.animation,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        })
    }, [info.animation]);

    return (
        <div ref={divEl} className={styles.info_icon}></div>
    )
}

export default SolutionHeroIcon;