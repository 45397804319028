import React from "react";

import Layout from "../../components/Layout";

import "./404.scss";

const Four0Four = () => {
    return (
        <Layout>
            <main className="four0four">
                <h1>Oops, this page doesn't exist! 🚀</h1>
            </main>
        </Layout>
    );
};

export default Four0Four;
