import React from "react";

import Header from "../Header";
import HeaderLinks from "../Header/HeaderLinks";
import Footer from "../Footer/";
import Legal from "../Legal";

const dashboardRoutes = [];

export default function Layout({ children }) {
    return (
        <>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="Everest"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 100,
                    color: "red",
                }}
            />
            {children}
            <Footer />
            <Legal />
        </>
    );
}
