import { toAbsoluteUrl } from "../../../_helpers/utils";

const PaymentsFlexible = ({ flexible }) => {

    return (
        <section className="mainFlexible">
            <div className="mainContainer">
                <div className="mainContent">
                    <div className="division" id="left">
                        <div className="divisionIn">
                            <h2 class="mainH2 wow fadeInUp" data-wow-delay=".3s">{flexible.title}</h2>
                            <p className="mainP wow fadeInUp" data-wow-delay=".3s">{flexible.description}</p>
                            <a className="ctaBlue wow fadeInUp" data-wow-delay=".3s" href={flexible.button_link_1}>{flexible.button_label_1}</a>
                        </div>
                    </div>
                    <div className="division" id="right">
                        <div className="divisionIn">
                            <img
                                alt={flexible.title}
                                src={toAbsoluteUrl(flexible.image)}
                                className="wow fadeInUp"
                                data-wow-delay=".3s"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentsFlexible;