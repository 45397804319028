import { toAbsoluteUrl } from "../../../_helpers/utils";
import Slider from "react-slick";

const PaymentsFeedback = ({ feedback }) => {

    function NextArrow(props) {
        const { style, onClick } = props;
        return (
            <img alt="Arrow" src={window.location.origin + '/images/slickRight.svg'} id="slickNext" onClick={onClick} style={{ ...style}} />
        );
    }
    function PrevArrow(props) {
        const { style, onClick } = props;
        return (
            <img alt="Arrow" src={window.location.origin + '/images/slickleft.svg'} id="slickPrev" onClick={onClick} style={{ ...style}} />
        );
    }

    const settings = {
        autoplay: false,
        autoplaySpeed: 3000,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    autoplay: false,
                    slidesToShow: 1,
                },
            },
        ],
    };
    
    return (
        <>
        {feedback.list && <section className="mainFeedback">
            <div className="mainContainer">
                <div className="mainContent">
                    <h2 className="mainH2 wow fadeInUp" data-wow-delay=".3s">{feedback.title}</h2>
                    <div className="slickWrap wow fadeInUp" data-wow-delay=".3s">
                        <Slider {...settings}>
                            { feedback.list && feedback.list.map((item, i) => (
                            <div key={i} className="single">
                                <div className="singleIn">
                                    <div className="head">
                                        {item.image &&
                                        <div className="person">
                                            <div className="personImg" style={{
                                                backgroundImage: `url("${toAbsoluteUrl(item.image)}")`
                                            }}></div>
                                        </div>
                                        }
                                        <div className="info">
                                            <p className="mainP name">{item.name}</p>
                                            <p className="mainP role">{item.role}</p>
                                        </div>
                                    </div>
                                    <div className="testimonialWrap">
                                        <p className="mainP">{item.testimonial}</p>
                                    </div>
                                </div>
                            </div>
                            )) }
                        </Slider>
                    </div>
                </div>
            </div>
        </section>        
        }
        </>
    )
}

export default PaymentsFeedback;