import { toAbsoluteUrl } from "../../../_helpers/utils";
import ReactMarkdown from "react-markdown";

const EnterpriseHero = ({ hero }) => {

    // On arrow click
    const scrollToSection = (event) => {
        var sectionID = event.target.attributes.getNamedItem('data-div-id').value;
        console.log(sectionID);

        var element = document.getElementById(sectionID);
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
        
    }

    // Check Scroll to Div
    const checkScroll = (event) => {        
        var sectionURL = event.target.attributes.getNamedItem('href').value;
        // Check if it starts with #
        if(sectionURL.charAt(0) === '#') {
            event.preventDefault();
            sectionURL = sectionURL.replace('#','');
            console.log(sectionURL);
            var element = document.getElementById(sectionURL);
            var headerOffset = 45;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    return (
        <section className="mainHero">
            <div className="mainContainer">
                <div className="mainContent">
                    <div className="division" id="left">
                        <div className="divisionIn">
                            <h1 className="mainH1 wow fadeInUp" data-wow-delay=".3s" dangerouslySetInnerHTML={{ __html : hero.title }}></h1>                       
                            <p className="mainP wow fadeInUp" data-wow-delay=".3s"><ReactMarkdown children={ hero.description } /></p>
                            <div className="wow fadeInUp actions" data-wow-delay=".3s">
                                <a className="ctaBlue" onClick={checkScroll} href={hero.button_link_1}>{hero.button_label_1}</a>
                                <a className="ctaGreen" onClick={checkScroll} href={hero.button_link_2}>{hero.button_label_2}</a>
                                <a className="ctaGreen" onClick={checkScroll} href={hero.button_link_3}>{hero.button_label_3}</a>
                            </div>
                            <div className="scrollArrow wow fadeInUp" data-wow-delay=".3s">
                                <img alt="Arrow" data-div-id="features" onClick={scrollToSection} src={window.location.origin + '/scroll-invoker.webp'} />
                            </div>
                        </div>
                    </div>
                    <div className="division" id="right">
                        <div className="divisionIn">
                            <img
                                alt={hero.title}
                                src={toAbsoluteUrl(hero.image)}
                                className="wow fadeInUp"
                                data-wow-delay=".3s"
                            />
                            {/* <div className="downloadApp wow fadeInUp" data-wow-delay=".3s">
                                <div className="downloadAppIn">
                                    <span>Download the app</span>
                                    <div className="appIcons">
                                        <div className="appIconsIn">
                                            <a href="https://play.google.com/store/apps/details?id=com.kotlin.everid.free" target="_blank" rel="noreferrer"><img src={window.location.origin + '/playstore.webp'} alt="Icon" style={{height: "35px",marginTop: "3px"}} /></a>
                                            <a href="https://apps.apple.com/us/app/everest-app/id1601096400" target="_blank" rel="noreferrer"><img src={window.location.origin + '/appstore.webp'} alt="Icon"/></a>
                                            <a href="https://wallet.everest.org/" target="_blank" rel="noreferrer"><img src={window.location.origin + '/deskstore.webp'} alt="Icon" style={{height: "32px"}} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EnterpriseHero;