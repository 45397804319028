import React from "react";
import ReactMarkdown from "react-markdown";

import Button from "../Button";

import { toAbsoluteUrl } from "../../_helpers/utils";

import "./InfoCards.scss";

const InfoCards = ({
    containerBackground,
    cardBackground,
    cards,
    centered,
}) => {
    return (
        <div
            style={{ backgroundImage: containerBackground }}
            className="infocards"
        >
            {cards.map((card, idx) => (
                <div
                    style={{
                        backgroundImage: cardBackground,
                    }}
                    key={idx}
                    className={`infocards__wrapper ${centered}`}
                >
                    {card.icon && (
                        <img
                            className="wow fadeInUp"
                            data-wow-delay=".3s"
                            alt={card.title}
                            src={toAbsoluteUrl(`${card.icon}`)}
                        />
                    )}
                    <h2
                        className="wow fadeInUp"
                        data-wow-delay=".4s"
                        dangerouslySetInnerHTML={{ __html: card.title }}
                    ></h2>
                    <article className="wow fadeInUp" data-wow-delay=".5s">
                        {card.description && (
                            <ReactMarkdown children={card.description} />
                        )}
                    </article>
                    {card.button_link && (
                        <Button
                            variant="primary"
                            text={card.button_label}
                            page={card.button_link}
                        />
                    )}
                    {card.button_label2 && (
                        <Button
                            variant="secondary"
                            text={card.button_label2}
                            page={card.button_link2}
                            external
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default InfoCards;
