import React from "react";

import styles from "./Legal.module.scss";

const Legal = () => {
    return (
        <div className={styles.legal}>
            Everest Network Ltd. is licensed by the Malta Financial Services
            Authority (MFSA) as a virtual financial asset service provider in
            terms of the VFA Act.
        </div>
    );
};

export default Legal;
