// import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers/utils";

const PaymentsIntegrate = ({ integrate }) => {

    return (
        <section className="mainIntegrate" id="integrate">
            <div className="mainContainer">
                <div className="mainContent">
                    <div className="division" id="left">
                        <div className="divisionIn">
                            <img
                                alt={integrate.title}
                                src={toAbsoluteUrl(integrate.image)}
                                className="wow fadeInUp"
                                data-wow-delay=".3s"
                            />
                        </div>
                    </div>
                    <div className="division" id="right">
                        <div className="divisionIn">
                            <div className="icons wow fadeInUp" data-wow-delay=".3s">
                                <img src={window.location.origin + '/images/appleIcon.svg'} id="apple" alt="Apple" />
                                <img src={window.location.origin + '/images/androidIcon.svg'} id="android" alt="Android" />
                                <img src={window.location.origin + '/images/linkIcon.svg'} id="link" alt="Link" />
                                <img src={window.location.origin + '/images/codeIcon.svg'} id="code" alt="Code" />
                            </div>
                            <h2 className="mainH2 wow fadeInUp" data-wow-delay=".3s" dangerouslySetInnerHTML={{ __html : integrate.title }}></h2>                       
                            <p className="mainP wow fadeInUp" data-wow-delay=".3s">
                            {integrate.description}
                            </p>
                            <div className="wow fadeInUp actions" data-wow-delay=".3s">
                                <a className="ctaBlue" href={integrate.button_link_1}>{integrate.button_label_1}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentsIntegrate;