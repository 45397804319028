import { useState } from "react";

const Newsletter = () => {

    const [subscribeStatus, setSubscribeStatus] = useState('empty');

    const submitNewsletter = (e) => {
        e.preventDefault();

        // https://moosendapp.docs.apiary.io/#reference/subscribers/add-or-update-subscribers/adding-subscribers
        // https://api.moosend.com/v3/subscribers/MailingListID/subscribe.Format?apikey=bafe55f4-e53e-4651-8164-c6d6ff05081b
        // https://everestdotorg.m-pages.com/juxnNL/subscribe
        var email = e.target[0].value;

        setSubscribeStatus('sending');
        var data = {'Email': email};

        // ERROR CODES
        // 501 = Email is not invalid
        // 0 = Success

        // MAILING LIST IDs
        // EverestDotOrg Mailing List (OGs) = b373c9e9-0f47-4c8d-b289-610e1cfcffc3
        // EverestDotOrg Mailing List = 4343c38a-0e5a-42fc-9626-85e927988966

        fetch('https://api.moosend.com/v3/subscribers/4343c38a-0e5a-42fc-9626-85e927988966/subscribe.json?apikey=ce958f65-4fa1-457c-80e4-f5faf080fae1',{
            method: 'post',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data),
        }).then(function (response) {
            if (response.ok) {
                return response.json();                
            }
            return Promise.reject(response);
        }).then(function (data) {            
            if(data['Code'] === 0) {
                setSubscribeStatus('success');
                console.log('Success');
            }
            else {
                setSubscribeStatus('invalid');
                console.log('Invalid');
            }
            console.log(data);                 
        }).catch(function (error) {
            setSubscribeStatus('error');
            console.log('ERROR');            
            console.warn(error);            
        });


    }

    return(
     <div className="newsletter" data-status={subscribeStatus}>
        <div className="newsletterIn">
            <h3 className="wow fadeInUp" data-wow-delay=".3s">Subscribe to newsletter</h3>
            <p className="mainP wow fadeInUp" data-wow-delay=".3s">
            Stay abreast of the newest innovations at Everest by joining our newsletter mailing list.
            </p>
            <form onSubmit={submitNewsletter} className="wow fadeInUp" data-wow-delay=".3s">
                <div className="formField">
                    <input type="text" name="email" placeholder="Email" />
                    <button className="ctaBlue" type="submit">Subscribe</button>
                </div>
                <p id="error">Please, type a valid email</p>
                <p id="sent">Subscribed successfully!</p>
            </form>
        </div>
     </div>
    )

}

export default Newsletter;