import Layout from "../../components/Layout/";

import SolutionHero from "./sections/SolutionHero";
import SolutionDetail from "./sections/SolutionDetail";
import SolutionNav from "./sections/SolutionNav";

import data from "./content.json";

const Solutions = () => {
    const { hero, detail } = data;

    // useEffect(() => {
    //     axios.get('https://everest-api-wunderdogs.netlify.app/.netlify/functions/server/twitter')
    //     // axios.get('http://localhost:3000/.netlify/functions/server/twitter')
    //     .then(function (response) {
    //         // handle success
    //         console.log(response.data);
    //     })
    //     .catch(function (error) {
    //         // handle error
    //         console.log(error);
    //     })
    //     .then(function () {
    //         // always executed
    //     });
    // }, []);

    return (
        <Layout>
            <main>
                { hero && <SolutionHero hero={hero} detail={detail} /> }
                { detail && <SolutionNav detail={detail} /> }
                { detail && detail.map( (info, i) =>  <SolutionDetail key={i} info={info} /> ) }
            </main>
        </Layout>
    );
};

export default Solutions;
