import React from "react";
import Button from "../Button/Button";
import styles from "./DemoCaseStudy.module.scss";

const DemoCaseStudy = ({ request_demo_btn, case_study_link, btn_position }) => {
    return (
        <div className={styles.demo_case_study} style={{ justifyContent: btn_position }}>
            <div>
                {request_demo_btn && (
                    <Button
                        variant="secondary"
                        text={"Request DEMO"}
                        page={"mailto:contact@everest.org"}
                        external
                    />
                )}
            </div>
            <div className={styles.demo_case_study__case}>
                {case_study_link && (
                    <Button
                        variant="case"
                        text={"See Case Study"}
                        page={case_study_link}
                        external
                    />
                )}
            </div>
        </div>
    );
};

export default DemoCaseStudy;
