import React from "react";
import ReactMarkdown from "react-markdown";

import Layout from "../../components/Layout";
import Hero from "../../components/Hero";
import HeroAlt from "../../components/HeroAlt";
import HeroVertical from "../../components/HeroVertical";
import InfoCards from "../../components/InfoCards";
import Button from "../../components/Button";
import ExchangeReward from "../../components/ExchangeReward/ExchangeReward";

import { toAbsoluteUrl, slugify } from "../../_helpers/utils";

import styles from "./home.module.scss";
import content from "./content.json";

const Home = () => {
    const { hero, payments, tokenization, custody, trading } = content;

    return (
        <Layout>
            <main className={styles.home}>
                {/* <ExchangeReward /> */}

                <Hero
                    title={hero.title}
                    description={hero.description}
                    button_label={hero.button_label}
                    button_link={hero.button_link}
                    additional_info={hero.additional_info}
                    tertiary_button_label={hero.tertiary_button_label}
                    tertiary_button_link={hero.tertiary_button_link}
                    image={hero.image}
                    mobile_image={hero.mobile_image}
                    // scroll_invoker
                    // animated
                />

                <hr className={styles.home__divider} />

                <HeroAlt
                    title={payments.title}
                    subtitle={payments.subtitle}
                    anchor={payments.anchor}
                    description={payments.description}
                    button_label={payments.button_label}
                    button_link={payments.button_link}
                    additional_info={payments.additional_info}
                    tertiary_button_label={payments.tertiary_button_label}
                    tertiary_button_link={payments.tertiary_button_link}
                    image={payments.image}
                    mobile_image={payments.mobile_image}
                    btn_position={payments.btn_position}
                    request_demo_btn={payments.request_demo_btn}
                    case_study_link={payments.case_study_link}
                    // scroll_invoker
                    // animated
                />
                
                <hr className={styles.home__divider} />

                <HeroAlt
                    title={tokenization.title}
                    subtitle={tokenization.subtitle}
                    anchor={tokenization.anchor}
                    description={tokenization.description}
                    button_label={tokenization.button_label}
                    button_link={tokenization.button_link}
                    additional_info={tokenization.additional_info}
                    tertiary_button_label={tokenization.tertiary_button_label}
                    tertiary_button_link={tokenization.tertiary_button_link}
                    image={tokenization.image}
                    mobile_image={tokenization.mobile_image}
                    btn_position={tokenization.btn_position}
                    request_demo_btn={tokenization.request_demo_btn}
                    case_study_link={tokenization.case_study_link}
                    // scroll_invoker
                    // animated
                />
                
                <hr className={styles.home__divider} />

                <HeroAlt
                    title={custody.title}
                    subtitle={custody.subtitle}
                    anchor={custody.anchor}
                    description={custody.description}
                    button_label={custody.button_label}
                    button_link={custody.button_link}
                    additional_info={custody.additional_info}
                    tertiary_button_label={custody.tertiary_button_label}
                    tertiary_button_link={custody.tertiary_button_link}
                    image={custody.image}
                    mobile_image={custody.mobile_image}
                    btn_position={custody.btn_position}
                    request_demo_btn={custody.request_demo_btn}
                    case_study_link={custody.case_study_link}
                    // scroll_invoker
                    // animated
                />
                
                <hr className={styles.home__divider} />

                <HeroAlt
                    title={trading.title}
                    subtitle={trading.subtitle}
                    anchor={trading.anchor}
                    description={trading.description}
                    button_label={trading.button_label}
                    button_link={trading.button_link}
                    additional_info={trading.additional_info}
                    tertiary_button_label={trading.tertiary_button_label}
                    tertiary_button_link={trading.tertiary_button_link}
                    image={trading.image}
                    mobile_image={trading.mobile_image}
                    btn_position={trading.btn_position}
                    request_demo_btn={trading.request_demo_btn}
                    case_study_link={trading.case_study_link}
                    // scroll_invoker
                    // animated
                />

                {/* Start Platform Features */}
                {/* <div
                    id="anchor"
                    className={styles.home__features}
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                            "/feature-gradient.png"
                        )})`,
                    }}
                >
                    <h2 className="wow fadeInUp" data-wow-delay=".3s">
                        {features.title}
                    </h2> */}

                    {/* Uncomment this section to reveal the older version of the plaform feature cards */}

                    {/* <div className={styles.home__features_cards}>
                        {features.cards.map((card, idx) => (
                            <div
                                className={styles.home__features_cards_wrapper}
                                key={idx}
                            >
                                <div
                                    className={
                                        styles.home__features_cards_wrapper_left
                                    }
                                >
                                    <img
                                        alt={card.title}
                                        src={toAbsoluteUrl(`${card.image}`)}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.home__features_cards_wrapper_right
                                    }
                                >
                                    <div
                                        className={
                                            styles.home__features_cards_wrapper_right_content
                                        }
                                    >
                                        <lottie-player
                                            class={
                                                styles.home__features_cards_wrapper_right_content_lottie
                                            }
                                            src={toAbsoluteUrl(
                                                `/animations/${slugify(
                                                    card.icon_title
                                                )}/${slugify(
                                                    card.icon_title
                                                )}.json`
                                            )}
                                            background="transparent"
                                            speed=".5"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                        <h3
                                            className="wow fadeInUp"
                                            data-wow-delay=".4s"
                                        >
                                            {card.title}
                                        </h3>
                                        <h5
                                            className="wow fadeInUp"
                                            data-wow-delay=".5s"
                                        >
                                            {card.subtitle}
                                        </h5>
                                        <div
                                            className={
                                                styles.home__features_cards_wrapper_right_content_description +
                                                " wow fadeInUp"
                                            }
                                            data-wow-delay=".6s"
                                        >
                                            <article>
                                                {card.description && (
                                                    <ReactMarkdown
                                                        children={
                                                            card.description
                                                        }
                                                    />
                                                )}
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}

                    {/* v2 feature cards */}
                    {/* <div className={styles.home__v2_features_cards}>
                        {features.cards.map((card, idx) => (
                            <div
                                className={
                                    styles.home__v2_features_cards_wrapper
                                }
                                key={idx}
                            >
                                <div
                                    className={
                                        styles.home__v2_features_cards_wrapper
                                    }
                                >
                                    <div
                                        className={
                                            styles.home__v2_features_cards_wrapper_content
                                        }
                                    >
                                        <lottie-player
                                            class={
                                                styles.home__v2_features_cards_wrapper_content_lottie +
                                                " wow rotateIn"
                                            }
                                            data-wow-delay=".3s"
                                            src={toAbsoluteUrl(
                                                `/animations/${slugify(
                                                    card.icon_title
                                                )}/${slugify(
                                                    card.icon_title
                                                )}.json`
                                            )}
                                            background="transparent"
                                            speed=".5"
                                            loop
                                            autoplay
                                        ></lottie-player>
                                        <h3
                                            className="wow fadeInUp"
                                            data-wow-delay=".4s"
                                        >
                                            {card.title}
                                        </h3>
                                        <h5
                                            className="wow fadeInUp"
                                            data-wow-delay=".5s"
                                        >
                                            {card.subtitle}
                                        </h5>
                                        <div
                                            className={
                                                styles.home__v2_features_cards_wrapper_content_description +
                                                " wow fadeInUp"
                                            }
                                            data-wow-delay=".6s"
                                        >
                                            <article>
                                                {card.description && (
                                                    <ReactMarkdown
                                                        children={
                                                            card.description
                                                        }
                                                    />
                                                )}
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                {/* End Platform Features */}

                {/* start solutions section */}
                {/* <div className={styles.home__solutions}>
                    <div className={styles.home__solutions_left}>
                        <img
                            className="wow fadeInLeft"
                            data-wow-delay=".9s"
                            alt={solutions.title}
                            src={toAbsoluteUrl("/images/Screen-1.png")}
                        />
                        <img
                            className="wow fadeInLeft"
                            data-wow-delay=".7s"
                            alt={solutions.title}
                            src={toAbsoluteUrl("/images/Screen-2.png")}
                        />
                        <img
                            className="wow fadeInLeft"
                            data-wow-delay=".5s"
                            alt={solutions.title}
                            src={toAbsoluteUrl("/images/Screen-1.png")}
                        />
                    </div>
                    <div className={styles.home__solutions_right}>
                        <div className={styles.home__solutions_right_content}>
                            <h2 className="wow fadeInUp" data-wow-delay=".3s">
                                {solutions.title}
                            </h2>
                            <div className="wow fadeInUp" data-wow-delay=".4s">
                                {solutions.description && (
                                    <ReactMarkdown
                                        children={solutions.description}
                                    />
                                )}
                            </div>
                            <div
                                className={
                                    styles.home__solutions_right_content_btns
                                }
                            >
                                <section
                                    className="wow fadeInUp"
                                    data-wow-delay=".5s"
                                >
                                    {solutions.button_link && (
                                        <Button
                                            variant="primary"
                                            text={solutions.button_label}
                                            page={solutions.button_link}
                                        />
                                    )}
                                </section>

                                <section
                                    className="wow fadeInUp"
                                    data-wow-delay=".6s"
                                >
                                    {solutions.button_link_2 && (
                                        <Button
                                            variant="secondary"
                                            text={solutions.button_label_2}
                                            page={solutions.button_link_2}
                                            external
                                        />
                                    )}
                                </section>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* end solutions section */}

                {/* Start Services */}
                {/* <InfoCards
                    cards={services}
                    containerBackground="linear-gradient(89.97deg, #35E0F4 -11.47%, #33D7F0 -3.71%, #2EBDE4 9.2%, #2594D0 25.64%, #195BB6 44.81%, #1132A3 57.23%, #1132A3 99.98%)"
                    cardBackground="linear-gradient(to top, #ededed, #fff), linear-gradient(to bottom, #fff, #fff)"
                /> */}
                {/* End Services */}

                {/* Start Featured */}
                {/* <div
                    className={styles.home__featured}
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                            "/feature-gradient.png"
                        )})`,
                    }}
                >
                    <Featured
                        title={featured.title}
                        description={featured.description}
                        logos={featured.logos}
                    />
                </div> */}
                {/* End Featured */}

                {/* Start Medium Posts */}
                {/* <div className={styles.home__medium}>
                    <h2 className="wow fadeInUp" data-wow-delay=".3s">
                        Latest From Everest
                    </h2>
                    <div className={styles.home__medium_btn}>
                        <a
                            className="wow fadeInUp"
                            data-wow-delay=".3s"
                            href="https://everestdotorg.medium.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            See on Medium
                        </a>
                    </div>
                </div>
                <MediumPosts />
                <div className={styles.home__medium_btn_mobile}>
                    <a
                        className="wow fadeInUp"
                        data-wow-delay=".3s"
                        href="https://everestdotorg.medium.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        See on Medium
                    </a>
                </div> */}
                {/* End Medium Posts */}
            </main>
        </Layout>
    );
};

export default Home;
