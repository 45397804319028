import React from "react";
import { Link } from "react-router-dom";

import "./Button.scss";

const Button = ({ variant, text, page, external }) => {
    return (
        <div>
            {external ? (
                <a
                    href={page}
                    target="_blank"
                    rel="noreferrer"
                    className={`btn__${variant}`}
                >
                    {text}
                </a>
            ) : (
                <Link to={page} className={`btn__${variant}`}>
                    {text}
                </Link>
            )}
        </div>
    );
};

export default Button;
