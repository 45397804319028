import React from "react";
import ReactMarkdown from "react-markdown";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import ScrollInvoker from "../../components/ScrollInvoker";
import TeamCards from "../../components/TeamCards";
import Partners from "../../components/Partners";
import Featured from "../../components/Featured";
import MediumPosts from "../../components/MediumPosts";
import Subscription from "../../components/Subscription";
import TwitterPosts from "../../components/TwitterPosts";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./about.module.scss";
import content from "./content.json";
import teamlist from "../../content/parsed/team.json";
import advisorylist from "../../content/parsed/advisory.json";

const About = () => {
    const { hero, partners, featured, subscription } = content;
    return (
        <Layout>
            <main className={styles.about}>
                {/* start hero section */}
                <div className={styles.about__hero}>
                    <img
                        className={styles.about__hero_gradient}
                        alt="gradient"
                        src={toAbsoluteUrl("/gradient.svg")}
                    />
                    <div className={styles.about__hero_left}>
                        <h1 className="wow fadeInUp" data-wow-delay=".3s">
                            {hero.title}
                        </h1>
                        <div
                            className={
                                styles.about__hero_left_content +
                                " wow fadeInUp"
                            }
                            data-wow-delay=".4s"
                        >
                            {hero.description && (
                                <ReactMarkdown children={hero.description} />
                            )}
                        </div>
                        {hero.button_link && (
                            <Button
                                variant="primary"
                                text={hero.button_label}
                                page={hero.button_link}
                            />
                        )}
                        <small>{hero.additional_info}</small>
                        {hero.tertiary_button_link && (
                            <Button
                                variant="arrow"
                                text={hero.tertiary_button_label}
                                page={hero.tertiary_button_link}
                            />
                        )}
                        <ScrollInvoker />
                    </div>
                    <div className={styles.about__hero_right}>
                        <div className={styles.about__hero_right_principles}>
                            <lottie-player
                                class={
                                    styles.about__hero_right_principles_lottie
                                }
                                src={toAbsoluteUrl(
                                    "/animations/about/mb/mb.json"
                                )}
                                background="transparent"
                                speed="1.5"
                                autoplay
                            ></lottie-player>
                            <lottie-player
                                class={
                                    styles.about__hero_right_principles_lottie_mobile
                                }
                                src={toAbsoluteUrl(
                                    "/animations/about/mb/mb.json"
                                )}
                                background="transparent"
                                speed="1.5"
                                autoplay
                            ></lottie-player>
                        </div>
                        {/* <div className={styles.about__hero_right_download}>
                            <h4>Download the app</h4>
                            <div
                                className={
                                    styles.about__hero_right_download_icons
                                }
                            >
                                <a
                                    href="http://play.google.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt="playstore"
                                        src={toAbsoluteUrl("/playstore.webp")}
                                    />
                                </a>
                                <a
                                    href="https://www.apple.com/app-store/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt="appstore"
                                        src={toAbsoluteUrl("/appstore.webp")}
                                    />
                                </a>
                                <a
                                    href="https://wallet.everest.org/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt="deskstore"
                                        src={toAbsoluteUrl("/deskstore.webp")}
                                    />
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* end hero section */}

                {/* start team section */}
                <div id="anchor" className={styles.about__team}>
                    <h2 className="wow fadeInUp" data-wow-delay=".3s">
                        Everest Team
                    </h2>
                    <TeamCards cards={teamlist} />
                </div>
                {/* end team section */}

                {/* start advisory board section */}
                <div className={styles.about__team}>
                    <h2 className="wow fadeInUp" data-wow-delay=".3s">
                        Advisory Board
                    </h2>
                    <TeamCards cards={advisorylist} />
                </div>
                {/* end advosory board section */}

                {/* start partners section   */}
                <div
                    className={styles.about__partners}
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                            "/feature-gradient.png"
                        )})`,
                    }}
                >
                    <Partners title={partners.title} logos={partners.logos} />
                    <Featured
                        title={featured.title}
                        description={featured.description}
                        logos={featured.logos}
                    />
                </div>
                {/* end partners section */}

                <div className={styles.about__socials}>
                    <h1 className="wow fadeInUp" data-wow-delay=".3s">
                        Latest From Everest
                    </h1>
                </div>

                {/* Start Twitter Posts */}
                <div className={styles.about__twitter}>
                    <img
                        className={styles.about__twitter_icon}
                        alt="twitter"
                        src={toAbsoluteUrl("/twitter-blue.svg")}
                    />
                    <div className={styles.about__twitter_btn}>
                        <a
                            className="wow fadeInUp"
                            data-wow-delay=".3s"
                            href="https://twitter.com/EverestDotOrg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            See on Twitter
                        </a>
                    </div>
                </div>
                <TwitterPosts />
                {/* End Twitter Posts */}

                {/* Start Medium Posts */}
                <div className={styles.about__medium}>
                    <img
                        className={styles.about__medium_icon}
                        alt="medium"
                        src={toAbsoluteUrl("/medium.svg")}
                    />
                    <div className={styles.about__medium_btn}>
                        <a
                            className="wow fadeInUp"
                            data-wow-delay=".3s"
                            href="https://everestdotorg.medium.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            See on Medium
                        </a>
                    </div>
                </div>
                <MediumPosts />
                {/* End Medium Posts */}

                {/* start subscription section */}
                <Subscription
                    title={subscription.title}
                    description={subscription.description}
                />
                {/* end subscription section */}
            </main>
        </Layout>
    );
};

export default About;
