import axios from "axios";

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const baseUrl = "https://api.twitter.com/2/";

/**
 * Convert any date into dd-mm-yyyy
 *
 * @param {*} date
 */
export const formatDate = (date) => {
    let d = new Date(date);
    // let monthNumber = "" + (d.getMonth() + 1);
    let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    // let monthName = monthNames[monthNumber];
    let monthName = monthNames[d.getMonth()];
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (day.length < 2) {
        day = "0" + day;
    }
    return [day, monthName, year].join(" ");
};

/*
 * Transform blog description into plain text
 */
export const toText = (node) => {
    let tag = document.createElement("div");
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
};

/*
 * Generate post excerpt from entire post content
 */
export const generateExcerpt = (text, startingPoint, maxLength) => {
    return text.length > maxLength
        ? text.slice(startingPoint, maxLength)
        : text;
};

/*
 *  This function takes in the anchor title as a parameter and converts it into a lowercase slug.
 *  The newly generated slug is then used to map each technology card to its animation icon.
 */
export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    /* remove accents, swap ñ for n, etc */
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid characters
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

    return str;
}

/*
 * Axios instance to used to fetch tweets from the Twitter V2 API
 */
export const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Authorization:
            'OAuth oauth_consumer_key="rI2PatccCZjiEc4o9UsHpymL1", consumer_secret: "cRddyRPAv6ASduwXaccXQYkaRvI5LafW4a9OmzFYhbwN8S81G1", access_token: "840382050-6L3hYKrONlS4Btqflx6syRNO0jUXs6S5R8mK3eYH",token_secret: "ks33ZLLdgb70wcCnEPiN4Kd7TuCromMeYjVK02pvSG0WG",bearer_token: "AAAAAAAAAAAAAAAAAAAAAJ5dRAEAAAAAer%2FS5GVFqmPBW4ooQwZcBTPyjQM%3D3eUULy91woMBnNCXKIeko3upsoNk17yo9jS26gBlM2vYGKYN4H",oauth_token="token",,oauth_signature_method="H,MAC-SHA1",,oauth_timestamp="1,515677408",,oauth_nonce="nonce",,oauth_version="1,.0",,oauth_signature="signature"',
    },
    withCredentials: true,
});
