import { toAbsoluteUrl } from "../../../_helpers/utils";
import ReactMarkdown from "react-markdown";
import $ from 'jquery';
import { useEffect } from "react";

const EnterpriseHero = ({ hero }) => { 

    // CHANGE WORDS
    useEffect(() => {
        var stop = false;
        $("[data-words]").attr("data-words", function(i, d){
            var $self  = $(this),
                $words = d.split("|"),
                tot    = $words.length,
                c      = 0; 
        
            // CREATE SPANS INSIDE SPAN
            for(var count=0; count<tot; count++) $self.append($('<span/>',{text:$words[count]}));
        
            // COLLECT WORDS AND HIDE
            $words = $self.find("span").hide();
        
            // ANIMATE AND LOOP
            
            (function loop(){
                if(!stop) {
                $self.animate({ width: $words.eq( c ).width() });
                $words.stop().fadeOut().eq(c).fadeIn().delay(2000).show(0, loop);
                c = ++c % tot;
                if(c === 0) { stop = true; }
                console.log('STOP IS: ' + stop);
                }
            }());
            
            
          });
    },[]);

    // On arrow click
    const scrollToSection = (event) => {
        var sectionID = event.target.attributes.getNamedItem('data-div-id').value;
        console.log(sectionID);

        var element = document.getElementById(sectionID);
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
        window.scrollTo({
             top: offsetPosition,
             behavior: "smooth"
        });
        
    }

    return (
        <section className="mainHero">
            <div className="mainHeroSecondShadow">
                <div className="mainContainer">
                    <div className="mainContent">
                        <div className="division" id="left">
                            <div className="divisionIn">
                                <h1 className="mainH1 wow fadeInUp" data-wow-delay=".3s"  dangerouslySetInnerHTML={{ __html : hero.title.replace('crypto','<span data-words="crypto|gaming|exchanges|VFA providers|you..."></span>') }}>                                    
                                </h1>  
                                                    
                                <div className="mainP wow fadeInUp" data-wow-delay=".3s">
                                {hero.description && (
                                    <ReactMarkdown
                                        children={
                                            hero.description
                                        }
                                    />
                                )}
                                </div>
                                <div className="wow fadeInUp actions" data-wow-delay=".3s">
                                    <a className="ctaBlue" href={hero.button_link_1}>{hero.button_label_1}</a>
                                    <a className="ctaGreen" href={hero.button_link_2}>{hero.button_label_2}</a>
                                </div>
                                <div className="scrollArrow wow fadeInUp" data-wow-delay=".3s">
                                    <img alt="Arrow" data-div-id="integrate" onClick={scrollToSection} src={window.location.origin + '/scroll-invoker.webp'} />
                                </div>
                            </div>
                        </div>
                        <div className="division" id="right">
                            <div className="divisionIn">
                                <img
                                    alt={hero.title}
                                    src={toAbsoluteUrl(hero.image)}
                                    className="wow fadeInUp"
                                    data-wow-delay=".3s"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EnterpriseHero;