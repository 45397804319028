import React from "react";
import ReactMarkdown from "react-markdown";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./Featured.module.scss";

const Featured = ({ title, description, logos }) => {
    return (
        <div className={styles.featured}>
            <h2 className="wow fadeInUp" data-wow-delay=".3s">
                {title}
            </h2>
            { description &&
                <article className="wow fadeInUp" data-wow-delay=".4s">
                    {description && <ReactMarkdown children={description} />}
                </article>
            }
            <div className={styles.featured__logos}>
                {logos.map((logo, idx) => (
                    <a href={logo.website} target="_blank" rel="noreferrer" key={idx}>
                        <img
                            className="wow fadeInUp"
                            data-wow-delay=".3s"
                            key={idx}
                            alt="logo"
                            src={toAbsoluteUrl(`${logo.logo}`)}
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default Featured;
