import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";

import { formatDate, toText, generateExcerpt } from "../../_helpers/utils";

import "./MediumPosts.scss";
import { feed } from './medium.data';

const MediumPosts = () => {
    const [posts, setPosts] = useState([]);

    const settings = {
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    autoplay: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        axios
            .get(
                "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@everestdotorg"
            )
            .then((res) => {
                setPosts([
                    ...res.data.items,
                    ...feed
                ]);
            });
    }, []);

    return (
        <div className="posts">
            <Slider {...settings}>
                {posts &&
                    posts.map((post) => (
                        <div key={post.pubDate} className="posts__wrapper">
                            <img src={post.thumbnail} alt={post.title} />
                            <div className="posts__wrapper_content">
                                <h5>{formatDate(post.pubDate)}</h5>
                                <h4>
                                    {" "}
                                    {generateExcerpt(toText(post.title), 0, 40)}
                                    {post.title.length > 40 ? "..." : ""}
                                </h4>
                                <p>
                                    {generateExcerpt(
                                        toText(post.description),
                                        0,
                                        130
                                    ) + "..."}
                                </p>
                                <a
                                    className="posts__wrapper_content_link"
                                    href={post.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Read More
                                </a>
                            </div>
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default MediumPosts;
