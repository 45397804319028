import React from "react";
import ReactMarkdown from "react-markdown";

import Button from "../Button";
import ScrollInvoker from "../ScrollInvoker";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./Hero.module.scss";

const Hero = ({
    title,
    subtitle,
    anchor,
    description,
    button_label,
    button_link,
    additional_info,
    tertiary_button_label,
    tertiary_button_link,
    image,
    mobile_image,
    scroll_invoker,
    animated,
}) => {
    return (
        <div className={styles.hero} id={anchor}>
            <div className={styles.hero__left}>
                <h1 className="wow fadeInUp" data-wow-delay=".3s">
                    {title}
                </h1>
                {subtitle && 
                <small className="wow fadeInUp" data-wow-delay=".3s">
                    {subtitle}
                </small>
                }
                <div
                    className={styles.hero__left_content + " wow fadeInUp"}
                    data-wow-delay=".4s"
                >
                    {description && <ReactMarkdown children={description} />}
                </div>
                {button_link && (
                    <Button
                        variant="primary"
                        text={button_label}
                        page={button_link}
                        external
                    />
                )}
                <small>{additional_info}</small>
                {tertiary_button_link && (
                    <Button
                        variant="primary"
                        text={tertiary_button_label}
                        page={tertiary_button_link}
                        external={true}
                    />
                )}
                {scroll_invoker && <ScrollInvoker />}
            </div>
            <div className={styles.hero__right}>
                {animated ? (
                    <div className={styles.hero__right_lottie}>
                        <lottie-player
                            class={styles.hero__right_lottie_desktop}
                            src={toAbsoluteUrl(
                                "/animations/home-screens/data.json"
                            )}
                            background="transparent"
                            speed="1"
                            autoplay
                        ></lottie-player>
                        <img
                            className={styles.hero__right_image_mobile}
                            alt="mobile-hero"
                            src={toAbsoluteUrl(`${mobile_image}`)}
                        />
                    </div>
                ) : (
                    <div className={styles.hero__right_image}>
                        <img
                            className={styles.hero__right_image_desktop}
                            alt="hero-img"
                            src={toAbsoluteUrl(`${image}`)}
                        />
                        <img
                            className={styles.hero__right_image_mobile}
                            alt="mobile-hero"
                            src={toAbsoluteUrl(`${mobile_image}`)}
                        />
                    </div>
                )}
                {/* <div className={styles.hero__right_download}>
                    <h4>Download the app</h4>
                    <div className={styles.hero__right_download_icons}>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.kotlin.everid.free"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt="playstore"
                                src={toAbsoluteUrl("/playstore.webp")}
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/us/app/everest-app/id1601096400"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt="appstore"
                                src={toAbsoluteUrl("/appstore.webp")}
                            />
                        </a>
                        <a
                            href="https://wallet.everest.org/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt="deskstore"
                                src={toAbsoluteUrl("/deskstore.webp")}
                            />
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Hero;
