import React from "react";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./Partners.module.scss";

const Partners = ({ title, logos }) => {
    return (
        <div className={styles.partners}>
            <h2 className="wow fadeInUp" data-wow-delay=".3s">
                {title}
            </h2>

            <div className={styles.partners__logos}>
                {logos.map((logo, idx) => (
                    <img
                        className="wow fadeInUp"
                        data-wow-delay=".3s"
                        key={idx}
                        alt="logo"
                        src={toAbsoluteUrl(`${logo.logo}`)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Partners;
