import React from "react";

import Layout from "../../components/Layout/Layout";
import ContentPage from "../../components/ContentPage/ContentPage";

import data from "./solidity-engineer.json";
import styles from "./careers.module.scss";

const SolidityEngineer = () => {
    const { content } = data;
    return (
        <Layout>
            <div className={styles.careers}>
                <h1>Careers</h1>
            </div>
            <ContentPage content={content} />
        </Layout>
    );
};

export default SolidityEngineer;
