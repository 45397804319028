import React from "react";
import ReactMarkdown from "react-markdown";

import Layout from "../../components/Layout/Layout";
//import Hero from "../../components/Hero/Hero";
import InfoCards from "../../components/InfoCards";

import { toAbsoluteUrl } from "../../_helpers/utils";

import styles from "./token.module.scss";
import content from "./content.json";

const Token = () => {
    const { hero2, packages, token_model } = content;
    return (
        <Layout>
            <main className={styles.token}>
            <div className={styles.token__details}>
                    <div className={styles.token__details_cards}>
                            <div
                                className={styles.token__details_cards_single}
                            >
                                <div>
                                    <img
                                        className="wow rotateIn"
                                        data-wow-delay=".3s"
                                        alt="ID Token"
                                        src={toAbsoluteUrl(`${hero2.image_left}`)}
                                    />
                                </div>
                                <div>
                                    <img
                                        className="wow rotateIn"
                                        data-wow-delay=".3s"
                                        alt="Learn more about the ID Token"
                                        src={toAbsoluteUrl(`${hero2.button_left}`)}
                                    />
                                </div>
                            </div>
                            <div
                                className={styles.token__details_cards_single}
                            >
                                <div>
                                    <img
                                        className="wow rotateIn"
                                        data-wow-delay=".3s"
                                        alt="CRDT Engine"
                                        src={toAbsoluteUrl(`${hero2.image_right}`)}
                                    />
                                </div>
                                <div>
                                    <img
                                        className="wow rotateIn"
                                        data-wow-delay=".3s"
                                        alt="Learn more about the CRDT engine"
                                        src={toAbsoluteUrl(`${hero2.button_right}`)}
                                    />
                                </div>
                            </div>
                    </div>
                </div>

                {/* start packages */}
                <div className={styles.token__packages}>
                    <InfoCards
                        cards={packages}
                        containerBackground="linear-gradient(127.45deg, #44DCB7 23.85%, #35E0F4 100%, rgba(68, 220, 183, 0) 100%)"
                        cardBackground="linear-gradient(to top, #ededed, #fff), linear-gradient(to bottom, #fff, #fff)"
                        centered
                    />
                </div>
                {/* end packages */}

                {/* start token model */}
                <div className={styles.token__model}>
                    <article className={styles.token__model_intro}>
                        {token_model.intro && (
                            <ReactMarkdown children={token_model.intro} />
                        )}
                    </article>

                    <div className={styles.token__model_bottom}>
                        <h2 className="wow fadeInUp" data-wow-delay=".3s">
                            {token_model.title}
                        </h2>
                        <article
                            className={styles.token__model_bottom_description}
                        >
                            {token_model.description && (
                                <ReactMarkdown
                                    children={token_model.description}
                                />
                            )}
                        </article>
                        <div className={styles.token__model_bottom_cards}>
                            {token_model.models.map((model, idx) => (
                                <div
                                    key={idx}
                                    className={
                                        styles.token__model_bottom_cards_single
                                    }
                                >
                                    {/* print the index of the array and add 1 because arrays are zero based */}
                                    <h2
                                        className="wow rotateIn"
                                        data-wow-delay=".4s"
                                    >
                                        {idx + 1}
                                    </h2>
                                    <article
                                        className="wow fadeInUp"
                                        data-wow-delay=".5s"
                                    >
                                        {model.description && (
                                            <ReactMarkdown
                                                children={model.description}
                                            />
                                        )}
                                    </article>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* end token model */}
            </main>
        </Layout>
    );
};

export default Token;
