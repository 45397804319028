// import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers/utils";

const PaymentsIntegrate = ({ transaction }) => {

    return (
        <section className="mainTransaction">
            <div className="mainContainer">
                <div className="mainContent">
                    <h2 className="mainH2 wow fadeInUp" data-wow-delay=".3s" dangerouslySetInnerHTML={{ __html : transaction.title }}></h2> 
                    <div className="allFeatures">
                    { transaction.list && transaction.list.map((item, i) => (
                        <div key={i} className="featureSingle">
                            <div className="featureSingleIn wow fadeInUp" data-wow-delay=".3s">
                                <div className="featureSingleHead">
                                <img
                                    id="icon"
                                    alt={item.title}
                                    src={toAbsoluteUrl(item.image)}
                                />
                                <h3>{ item.title }</h3>
                                <p className="mainP nwow fadeInUp" data-wow-delay=".3s">{ item.content }</p>
                                </div>
                            </div>
                        </div>
                    )) }
                </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentsIntegrate;