import Newsletter from "../../../components/Newsletter/newsletter";

const PaymentsNewsletter = ({ transaction }) => {

    return (
        <section className="mainNewsletter">
            <div className="mainContainer">
                <div className="mainContent">
                    <Newsletter />
                </div>
            </div>
        </section>
    )
}

export default PaymentsNewsletter;