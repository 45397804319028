// import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers/utils";
import ReactMarkdown from "react-markdown";

const EnterpriseContent = ({ content }) => {

    return (
        <section className="mainBlock">
            <div className="mainContainer">
                <div className="mainContent">
                    <div className="division" id="left">
                        <div className="divisionIn">
                            <h2 className="mainH2 wow fadeInUp" data-wow-delay=".3s">{ content.title }</h2>                        
                            <p className="mainP wow fadeInUp" data-wow-delay=".3s"><ReactMarkdown children={content.content} /></p>
                            <div className="wow fadeInUp" data-wow-delay=".3s">
                                <a className="ctaBlue" href={content.button_link_1}>{content.button_label_1}</a>
                            </div>
                        </div>                        
                    </div>
                    <div className="division" id="right">
                        <div className="divisionIn">
                            <img
                                alt={content.title}
                                src={toAbsoluteUrl(content.image)}
                                className="wow fadeInUp"
                                data-wow-delay=".3s"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EnterpriseContent;